const selectors = {
  sliderContainer: ".carousel",
  navButtons: ".carousel__navigation-buttons",
  scrollBar: ".carousel__scrollbar",
};

export default () => {
  const gridCarouselEls = [...document.querySelectorAll('.grid .carousel')]

  const initSlider = () => {
    if (gridCarouselEls && gridCarouselEls.length) {
      gridCarouselEls.forEach((list, index) => {
        import(flu.chunks.swiper /* @vite-ignore */).then(function (_ref) {
          const navigationWrapper = list.querySelector("[data-navigation]");
          const nextButton = list.querySelector("[data-next]");
          const prevButton = list.querySelector("[data-prev]");
          const scrollBarElm = list.querySelector(".carousel__scrollbar");
          const autoPerView = list.dataset.sliderView
          const Swiper = _ref.Swiper,
            Navigation = _ref.Navigation,
            Scrollbar = _ref.Scrollbar;

          let swiperOptions = {
            modules: [Navigation, Scrollbar],
            slidesPerView: 1.3,
            grabCursor: true,
            effect: "fade",
            fadeEffect: {
              crossFade: false
            },
            navigation: {
              nextEl: nextButton,
              prevEl: prevButton
            },
            scrollbar: {
              el: scrollBarElm,
              draggable: true
            },
            watchSlidesProgress: true,
            loop: false,
            breakpoints: {
              500: {
                slidesPerView: 2.3
              },
              768: {
                slidesPerView: 3.5
              },
              1200: {
                slidesPerView: autoPerView && autoPerView === 'auto' ? 'auto' : 4
              }
            },
            on: {
              init: function init() {
                list.parentElement.classList.add("slider-active")
              },
              slideChangeTransitionEnd: function slideChangeTransitionEnd() {
                var slideEls = this.slides;
                setTimeout(function () {
                  slideEls.forEach(function (slide) {
                    slide.toggleAttribute("inert", !slide.classList.contains("swiper-slide-active"));
                  });
                }, 50);
              }
            }
          };

          const swiper = new Swiper(list, swiperOptions)
        })
      })
    }
  }

  initSlider()
}
